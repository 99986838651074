import React from 'react'
import Image from 'gatsby-image'
import { useIntl } from 'react-intl'

import { getURI } from '~utilities'

import ServiceCard from '~components/directory/service-card'

const Categories = props => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()
  let categories = ''

  if (props.serviceCategoriesRef) {
    categories = props.serviceCategoriesRef.map(category => {
      const categoryImage = {
        fluid: { ...category.image.fluid },
        style: {
          position: 'absolute',
          width: '100%',
          height: '100%'
        }
      }

      const image = (
        <Image
          fluid={categoryImage.fluid}
          style={categoryImage.style}
          className='absolute w-full h-full object-cover opacity-50 group-hover:grayscale'
        />
      )

      return (
        <div className='md:w-1/2 lg:w-1/4 px-4' key={category.slug}>
          <ServiceCard
            image={image}
            title={category.name}
            body={category.headline}
            color={category.color}
            uri={getURI(category, key)}
          />
        </div>
      )
    })

    return categories || null
  }
}

export default Categories
