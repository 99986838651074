import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'

const ServiceCard = ({ color, image, title, body, uri, extraClasses }) => {
  const serviceCardClasses = `flex mb-8 shadow-lg ${extraClasses}`
  const serviceCardInnerClasses = `relative flex flex-col all-center w-full h-full p-4 text-white hover:bg-${color.toLowerCase()}-translucent`
  const whitelistForBgClasses = `hover:bg-blue-translucent hover:bg-green-translucent hover:bg-orange-translucent hover:bg-purple-translucent hover:bg-red-translucent`

  return (
    <div className={serviceCardClasses}>
      <div className='relative flex w-full h-64 bg-black group'>
        {image}
        <div className='absolute w-full h-full' />
        <div className={serviceCardInnerClasses}>
          <div className='mt-4 font-bold text-2xl leading-tight text-center'>{title}</div>
          <div className='hidden group-hover:block px-4 text-sm leading-snug'>
            <div className='text-clamp-4'>{body}</div>
          </div>
          <div className='hidden group-hover:block mt-4'>
            <div className='button button-pink-solid'>
              <Link to={uri}>
                <FormattedMessage id='more' /> <em className='fas fa-caret-right' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceCard
