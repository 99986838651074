import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { navigate } from '@reach/router'
import { FormattedMessage } from 'react-intl'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '~components/layout'
import SEO from '~components/seo'
import Categories from '~components/directory/categories'
import { Bold, Italic } from '~components/shared'

const Services = props => {
  const { section, locale } = props.pageContext
  const key = locale.toLowerCase()
  const { location } = props

  const [searchKey, setSearchKey] = useState('')

  function submitSearch (event) {
    event.preventDefault()
    navigate(`/${key}/search/?q=${searchKey}`)
  }

  const services = props.data.contentfulServicesDirectory

  let servicesDirectoryDocument = {
    ...services
  }

  let title = ''
  let headline = ''
  let subline = ''

  if (servicesDirectoryDocument.title) {
    title = servicesDirectoryDocument.title
  }

  if (servicesDirectoryDocument.headline) {
    headline = servicesDirectoryDocument.headline
  }

  if (servicesDirectoryDocument.subline) {
    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <div className='body-1 mt-8 lg:px-32 text-center'>{children}</div>
      }
    }
    subline = documentToReactComponents(servicesDirectoryDocument.subline.json, options)
  }

  return (
    <Layout locale={locale} location={location} section={section}>
      <SEO lang={locale} title={services?.metaTitle} description={services?.metaDescription} />
      <div>
        <div className='max-w-page py-8'>
          <div className='headline-1 text-center'>{headline}</div>
          {subline}
          <div className='flex flex-col md:flex-row flex-wrap -mx-4 mt-16'>
            <Categories {...servicesDirectoryDocument} />
          </div>
        </div>
        <div className='wave-top' />
        <div className='bg-cyan py-16'>
          <div className='max-w-page flex all-center mx-auto'>
            <div className='flex-col w-full pb-16'>
              <div className='headline-section-cyan-centered'>
                <div>{servicesDirectoryDocument.searchHeadline}</div>
                <hr aria-hidden />
              </div>

              <form onSubmit={submitSearch} className='flex items-center h-12 mt-16 lg:px-32 text-black'>
                <div className='flex all-center h-full border-l border-y border-gray bg-white rounded-l-lg pl-4 py-2 text-lg'>
                  <em className='fas fa-search' />
                </div>

                <input
                  placeholder={servicesDirectoryDocument.searchPlaceholderText}
                  className='flex flex-grow h-full px-4 py-2 border-y border-gray bg-white text-lg text-black whitespace-no-wrap overflow-hidden'
                  aria-label={servicesDirectoryDocument.searchPlaceholderText}
                  onChange={e => setSearchKey(e.target.value)}
                />
                <button
                  type='submit'
                  className='submit flex all-center h-full p-2 bg-green-dark hover:bg-white border border-green rounded-r-lg font-nunito font-extrabold text-lg text-white hover:text-green whitespace-no-wrap cursor-pointer'
                >
                  <FormattedMessage id='search' /> <em className='ml-2 fas fa-caret-right' />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Services

export const servicesDirectoryQuery = graphql`
  query servicesDirectoryQuery($locale: String!) {
    contentfulServicesDirectory(node_locale: { eq: $locale }) {
      title
      metaTitle
      metaDescription
      headline
      subline {
        json
      }
      serviceCategoriesRef {
        internal {
          type
        }
        node_locale
        slug
        type
        color
        name
        headline
        subline {
          json
        }
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      searchHeadline
      searchPlaceholderText
    }
  }
`
